<template>
  <div class="columns is-vcentered is-justify-content-space-between">
    <div class="column is-8-widescreen is-7-tablet is-12-mobile">
      <div class="columns is-vcentered is-multiline">
        <div class="column vrm-column">
          <label class="label is-size-7">VRM</label>
        </div>
        <div class="column year-column">
          <label class="label is-size-7">Year</label>
        </div>
        <div class="column colour-column">
          <label class="label is-size-7">Colour</label>
        </div>
        <div class="column dealer-column">
          <label class="label is-size-7">Seller Type</label>
        </div>
      </div>
    </div>
    <div class="column is-3-widescreen is-4-tablet is-12-mobile">
      <div
        class="columns is-vcentered is-multiline is-justify-content-space-between"
      >
        <div class="column is-narrow">
          <label class="label is-size-7">Mileage</label>
        </div>
        <div class="column is-8 has-text-right">
          <label class="label is-size-7">Price</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SimilarListingsHeaders'
}
</script>
